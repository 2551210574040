:root {
  --bs-body-font-family: "Space Grotesk";
  --grey-a: #bfccc6;
  --grey-b: #4b4f51;
  --grey-c: #f7f8f81a;
  --grey-d: #00000069;
  --grey-e: #ffffff85;
  --grey-f: #cfcfcf;
  --black-a: #000805;
  --shadow-a: 5px 3px 18.3px 0px #000;
  --gradient-a: radial-gradient(47.66% 172.22% at 50% -72.22%, #fff3 25%, #fff0 100%), linear-gradient(95deg, #252b2e82 3.15%, #37495263 110%);
  --gradient-b: linear-gradient(180deg, #fff0 0%, #ffffff0a 100%);
  --gradient-c: linear-gradient(180deg, #363636b8 0%, #2e2e2ebd 100%);
}

html, body {
  font-family: Space Grotesk !important;
  font-size: 1rem !important;
}

th {
  text-wrap: nowrap;
}

@media (width >= 576px) {
  .px-sm-10 {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}

.nice-scrollbar::-webkit-scrollbar {
  background-color: var(--grey-a);
  border-radius: .5rem;
  width: .5rem;
  height: 100%;
}

body::-webkit-scrollbar {
  background-color: var(--grey-a);
  border-radius: .5rem;
  width: .5rem;
  height: 100%;
}

.nice-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--grey-b);
  border-radius: .5rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--grey-b);
  border-radius: .5rem;
}

.blur-1 {
  backdrop-filter: blur(4.2px);
}

.font-poppins {
  font-family: Poppins !important;
}

.font-monument {
  font-family: Monument Extended !important;
}

.font-san {
  font-family: "Core Sans D 55 Bold" !important;
}

.font-space {
  font-family: Space Grotesk !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-12 svg {
  width: 12px !important;
  height: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-14 svg {
  width: 14px !important;
  height: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-16 svg {
  width: 16px !important;
  height: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-18 svg {
  width: 18px !important;
  height: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-20 svg {
  width: 20px !important;
  height: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-22 svg {
  width: 22px !important;
  height: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-24 svg {
  width: 24px !important;
  height: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-26 svg {
  width: 26px !important;
  height: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-28 svg {
  width: 28px !important;
  height: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-30 svg {
  width: 30px !important;
  height: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-32 svg {
  width: 32px !important;
  height: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-34 svg {
  width: 34px !important;
  height: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-36 svg {
  width: 36px !important;
  height: 36px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-38 svg {
  width: 38px !important;
  height: 38px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-40 svg {
  width: 40px !important;
  height: 40px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.text-grey-a {
  color: var(--grey-a) !important;
}

.text-grey-e {
  color: var(--grey-e) !important;
}

.text-grey-f {
  color: var(--grey-f) !important;
}

.text-grey-b {
  color: var(--grey-b) !important;
}

.border-grey-a {
  border-color: var(--grey-a) !important;
}

.border-grey-b {
  border-color: var(--grey-b) !important;
}

.border-grey-c {
  border-color: var(--grey-c) !important;
}

.bg-gradient-a {
  background: var(--gradient-a) !important;
}

.bg-gradient-b {
  background: var(--gradient-b) !important;
}

.bg-gradient-c {
  background: var(--gradient-c) !important;
}

.bg-black-a {
  background: var(--black-a) !important;
}

.bg-grey-d {
  background: var(--grey-d) !important;
}

.shadow-a {
  box-shadow: var(--shadow-a);
}

.mask-gradient-a {
  background: linear-gradient(#d9d9d9 0%, #fff0 100%);
}

.table > tbody > tr > th {
  vertical-align: middle !important;
}

.global-toast-layer {
  pointer-events: none;
  z-index: 1000000;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 6.25rem;
  display: flex;
  position: fixed;
  top: 0;
}

.global-toast-layer > .global-toast-item {
  pointer-events: all;
  background-color: var(--grey-b);
  color: #fff;
  word-break: break-word;
  border-radius: .5rem;
  max-width: 60vw;
  padding: 1.25rem 1.75rem;
}

.global-toast-layer > .global-toast-item.global-toast-item-error {
  background-color: var(--bs-danger);
}

.global-toast-layer .toast-success {
  background: var(--bs-success);
}

.global-toast-layer .toast-warning {
  background: var(--bs-warning);
}
/*# sourceMappingURL=index.b5d232b9.css.map */
