:root {
    --bs-body-font-family: 'Space Grotesk';
    --grey-a: #bfccc6;
    --grey-b: #4b4f51;
    --grey-c: rgba(247, 248, 248, 0.1);
    --grey-d: rgba(0, 0, 0, 0.41);
    --grey-e: rgba(255, 255, 255, 0.52);
    --grey-f: #cfcfcf;
    --black-a: #000805;

    --shadow-a: 5px 3px 18.3px 0px #000;

    --gradient-a: radial-gradient(
            47.66% 172.22% at 50% -72.22%,
            rgba(255, 255, 255, 0.2) 25%,
            rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
            95deg,
            rgba(37, 43, 46, 0.51) 3.15%,
            rgba(55, 73, 82, 0.39) 110%
        );
    --gradient-b: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.04) 100%
    );
    --gradient-c: linear-gradient(
        180deg,
        rgba(54, 54, 54, 0.72) 0%,
        rgba(46, 46, 46, 0.74) 100%
    );
}

html,
body {
    font-family: 'Space Grotesk' !important;
    font-size: 1rem !important;
}

th {
    text-wrap: nowrap;
}

.px-sm-10 {
    @media (min-width: 576px) {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
}

.nice-scrollbar,
body {
    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 100%;
        background-color: var(--grey-a);
        border-radius: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--grey-b);
        border-radius: 0.5rem;
    }
}

.blur-1 {
    backdrop-filter: blur(4.2px);
}

.font-poppins {
    font-family: 'Poppins' !important;
}

.font-monument {
    font-family: 'Monument Extended' !important;
}

.font-san {
    font-family: 'Core Sans D 55 Bold' !important;
}

.font-space {
    font-family: 'Space Grotesk' !important;
}

/**
 * Custom classes
 * font size (12/14/16...40) => fs-12 fs-14...
 */
each(range(15), {
    @size: 10 + @value * 2;

    .fs-@{size} {
        font-size: unit(@size, px) !important;
        svg {
            width: unit(@size, px) !important;
            height: unit(@size, px) !important;
        }
    }
});

/**
 * Custom font weight
 * font weight (500/600/700) => fw-500 fw-600...
 */
each(range(4), {
    @size: 400 + @value * 100;

    .fw-@{size} {
        font-weight: @size !important;
    }
});

each(range(5), {
    @size: 0 + @value * 1;

    .z-@{size} {
        z-index: @size !important;
    }
});

.text-grey-a {
    color: var(--grey-a) !important;
}

.text-grey-e {
    color: var(--grey-e) !important;
}

.text-grey-f {
    color: var(--grey-f) !important;
}

.text-grey-b {
    color: var(--grey-b) !important;
}

.border-grey-a {
    border-color: var(--grey-a) !important;
}

.border-grey-b {
    border-color: var(--grey-b) !important;
}

.border-grey-c {
    border-color: var(--grey-c) !important;
}

.bg-gradient-a {
    background: var(--gradient-a) !important;
}

.bg-gradient-b {
    background: var(--gradient-b) !important;
}

.bg-gradient-c {
    background: var(--gradient-c) !important;
}

.bg-black-a {
    background: var(--black-a) !important;
}

.bg-grey-d {
    background: var(--grey-d) !important;
}

.shadow-a {
    box-shadow: var(--shadow-a);
}

.mask-gradient-a {
    background: linear-gradient(
        180deg,
        #d9d9d9 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

.table > tbody > tr > th {
    vertical-align: middle !important;
}

// toast style
.global-toast-layer {
    position: fixed;
    padding-top: 6.25rem;
    pointer-events: none;
    top: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    z-index: 1000000;

    align-items: center;
    > .global-toast-item {
        pointer-events: all;
        padding: 1.25rem 1.75rem;
        border-radius: 0.5rem;
        background-color: var(--grey-b);
        color: #fff;
        max-width: 60vw;
        word-break: break-word;
        &.global-toast-item-error {
            background-color: var(--bs-danger);
        }
    }

    .toast-success {
        background: var(--bs-success);
    }

    .toast-warning {
        background: var(--bs-warning);
    }
}
